.dataResult {
  margin-top: 15px;
  width: 300px;
  height: 150px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 10px;
  position: fixed;
}

.dataResult .dataItem {
  color: #6b011f;
  display: flex;
  width: 100%;
  height: 50px;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataItem p {
  margin-left: 10px;
  padding: 10px;
}

a {
  text-decoration: none;
}

@media (max-width: 991px) {
  .dataResult {
    margin-top: 15px;
    width: 300px;
    height: 100px;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 10px;
  }

  .dataResult .dataItem {
    color: #6b011f;
    display: flex;
    width: 100%;
    height: 50px;
  }

  .dataItem p {
    margin-left: 10px;
    padding: 10px;
  }

  a {
    text-decoration: none;
  }
}
