.contact-page {
  margin-top: 20%;
  margin-bottom: 7%;
}

.contactpage-content {
  margin: auto auto;
  width: 60%;
  text-align: center;
  color: white;
}

.contactpage-content h1 {
  font-family: "Amatic SC", cursive;
  font-size: 60px;
}

.contactus-submitbtn {
  background-color: #6b011f;
  border: none;
  width: 70%;
  height: 50px;
  margin-bottom: 10px;
}

.contact-us-textarea {
  width: 100%;
  border-radius: 10px;
  height: 100px;
}

/* about us */
.aboutus-container {
  text-align: center;
  margin-top: 20%;
  margin-bottom: 50px;
}

.aboutus-container h1 {
  font-family: "Amatic SC", cursive;
  font-size: 80px;
}

.aboutus-img {
  width: 40%;
  border-radius: 20px;
  margin: auto auto;
  /* margin-top: 10%; */
  box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.9);
}

.aboutus-text {
  color: black;
  padding: 40px 80px;
  margin-top: 20px;
  font-family: "Tilt Neon", cursive;
}

/* exchnages and returns */
.backto-store-button {
  margin: 1% 1%;
  background-color: gray;
  border: none;
}

.exchange-logo-outline {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: auto auto;
  margin-top: 25px;
  background-color: #171717;
  box-shadow: 1px 15px 15px rgb(0, 0, 0, 0.8);
  opacity: 0.5;
}

.exch-form {
  width: 50%;
  margin: auto auto;
  border: solid white;
  box-shadow: 0px 0px 25px rgb(0, 0, 0, 0.3);
  border-radius: 20px;
  padding: 20px 20px;
  margin-top: 40px;
  /* margin-bottom: 10%; */
  display: flex;
  height: 100%;
}

.exchform-content {
  margin: 10% auto;
  width: 80%;
  font-weight: bold;
}

.exch-returns-content {
  height: 100%;
  margin-top: 15%;
  margin-bottom: 40px;
}

.exch-returns-content p {
  font-family: "Tilt Neon", cursive;
  font-size: 25px;
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 60px;
}

.exch-textfield {
  height: 70px;
}

.return-button {
  margin: auto 25%;
  width: 50%;
  background-color: gray;
  border: none;
  height: 50px;
  margin-top: 10px;
}

/* style={{ marginTop: "20px", marginRight: "90%" }} */

.success-contactus-content {
  margin-top: 10%;
}

.homepage-button {
  margin-top: 20px;
  margin-right: 90%;
  background-color: #6b011f;
  border: none;
}

.contact-success-title {
  color: white;
  font-family: "Tilt Neon", cursive;
  margin: auto auto;
  width: 100%;
}

.dog-chasing {
  width: 8%;
  height: 10%;
  margin-top: 5%;
  margin-right: 5%;
}

.truck-wind {
  width: 10%;
  height: 20%;
}

.mailman-truck {
  width: 20%;
  height: 30%;
}

.warranty-content {
  margin-top: 15%;
}

.warranty-content p {
  font-family: "Tilt Neon", cursive;
  font-size: 30px;
}

@media (max-width: 992px) {
  .aboutus-container {
    margin-top: 45%;
    text-align: center;
    margin-bottom: 20px;
  }

  .aboutus-container h1 {
    font-family: "Amatic SC", cursive;
    font-size: 50px;
  }

  .aboutus-img {
    width: 90%;
    box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.9);
    margin: auto auto;
  }

  .aboutus-text {
    color: black;
    margin-top: 30px;
    font-size: 17px;
    padding: 10px 10px;
  }

  /* contact us  */
  .contact-page {
    margin-top: 60%;
  }

  .contactpage-content {
    margin: auto auto;
    width: 60%;
    text-align: center;
    color: white;
  }

  .contactpage-content h1 {
    font-family: "Amatic SC", cursive;
    font-size: 40px;
  }

  .contactpage-content p {
    font-size: 13px;
  }

  .contact-us-textarea {
    width: 100%;
    border-radius: 10px;
    height: 100px;
  }

  .contactus-submitbtn {
    background-color: #6b011f;
    border: none;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }

  /* exchnages and returns */
  .backto-store-button {
    margin: 3% 3%;
    background-color: gray;
    border: none;
  }

  .exchange-logo-outline {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: auto auto;
    margin-top: 25px;
    background-color: #171717;
    box-shadow: 1px 15px 15px rgb(0, 0, 0, 0.8);
    opacity: 0.5;
  }

  .exch-logo {
    width: 150px;
  }

  .exch-form {
    width: 90%;
    margin: auto auto;
    border: solid white;
    box-shadow: 0px 0px 25px rgb(0, 0, 0, 0.3);
    border-radius: 20px;
    padding: 20px 20px;
    margin-top: 40px;
    display: flex;
    height: 100%;
  }

  .exchform-content {
    width: 80%;
  }

  .exch-returns-content {
    height: 100%;
    margin-top: 30%;
    margin-bottom: 20px;
  }

  .exch-returns-content p {
    font-family: "Tilt Neon", cursive;
    font-size: 20px;
  }

  .form-header {
    text-align: center;
    font-size: 30px;
    /* margin-bottom: 50px; */
  }

  .exch-textfield {
    margin: auto auto;
    height: 70px;
  }

  .return-button {
    margin: auto auto;
    width: 50%;
    background-color: gray;
    border: none;
    height: 50px;
    display: inline;
    margin: auto 25%;
  }

  /* SUCCESS CONTACT PAGE */

  .success-contactus-content {
    margin-top: 20%;
  }

  .homepage-button {
    margin-top: 30px;
    margin-right: 60%;
    background-color: #6b011f;
    border: none;
  }

  .contact-success-title {
    font-family: "Tilt Neon", cursive;
    color: white;
    margin: auto auto;
    display: flex;
  }

  .dog-chasing {
    width: 20%;
    margin-top: 10%;
    margin-right: 5%;
  }

  .truck-wind {
    width: 20%;
  }

  .mailman-truck {
    width: 40%;
  }

  .warranty-content {
    margin-top: 30%;
  }

  .warranty-content p {
    font-family: "Tilt Neon", cursive;
    font-size: 15px;
  }
}
